<template>
    <v-card-text v-if="showCheckbox">
        <div class="mb-6">
            <div class="label-txt text-capitalize mb-2">
                Please select the recipient for the OTP
            </div>
            <v-checkbox
                hide-details="auto"
                v-for="manager in branchManagers"
                :key="manager.id"
                v-model="selectedManager"
                :label="manager.name"
                :value="manager.id"
                color="secondary"
            ></v-checkbox>
        </div>

        <div class="btn-wrapper mb-4 justify-space-between">
            <v-btn text class="b-btn btn-text mr-5" @click="$emit('back')"
                >Go Back</v-btn
            >
            <v-btn
                width="40%"
                depressed
                class="height-45 font-14 line-h-17 font-weight--bold b-login-btn b-btn b-primary"
                :loading="loading"
                :disabled="!selectedManager"
                @click="generateOtp"
                >Send OTP
            </v-btn>
        </div>
    </v-card-text>

    <v-card-text v-else>
        <div class="text-center mb-4">
            We've sent a verification code to<br />
            <strong>{{ maskEmail(form.email) }}</strong>
        </div>

        <div class="mb-6">
            <div class="d-flex justify-center">
                <v-otp-input
                    v-model="otp"
                    :length="6"
                    type="number"
                ></v-otp-input>
            </div>
            <div
                v-if="showError"
                class="text-capitalize text-center text-red text-body-2"
            >
                {{ errorMessage }}
            </div>
        </div>

        <div class="btn-wrapper mb-4 justify-space-between">
            <v-btn
                text
                class="b-btn btn-text mr-5"
                @click="branchManagers ? (showCheckbox = true) : $emit('back')"
                >Go Back</v-btn
            >
            <v-btn
                width="40%"
                depressed
                class="height-45 font-14 line-h-17 font-weight--bold b-login-btn b-btn b-primary"
                :loading="loading"
                :disabled="otp.length !== 6 || loading"
                @click="verifyOTP"
                >Submit</v-btn
            >
        </div>

        <div class="text-center">
            <span class="text-body-2">Didn't receive the code? </span>
            <v-btn
                text
                small
                color="primary"
                class="text-capitalize"
                :disabled="resendTimer > 0 || loading"
                @click="resendOTP"
            >
                {{ resendTimer > 0 ? `Resend in ${resendTimer}s` : "Resend" }}
            </v-btn>
        </div>
    </v-card-text>
</template>

<script>
import { mapActions } from "vuex";
import {
    VERIFY_OTP,
    RESEND_OTP,
    GENERATE_OTP,
} from "../../../store/action-types";
export default {
    name: "OTPVerification",
    props: ["form", "branchManagers", "userId"],
    data: () => ({
        otp: "",
        email: "",
        loading: false,
        resendTimer: 0,
        showError: false,
        errorMessage: "",
        timerInterval: null,

        // Branch managers
        showCheckbox: false,
        selectedManager: "",
    }),
    methods: {
        ...mapActions({
            VERIFY_OTP,
            RESEND_OTP,
            GENERATE_OTP,
        }),
        async verifyOTP() {
            this.loading = true;
            try {
                const res = await this.VERIFY_OTP({
                    email: this.form.email,
                    otp: this.otp,
                });

                if (res.result == "fail") {
                    this.errorMessage = res.message;
                    this.showError = true;
                    this.otp = "";
                } else {
                    window.location.href = "/home";
                }

                // this.$store.dispatch("snackbar/SHOW", res);
            } catch (error) {
                this.errorMessage = error.message;
                this.showError = true;
                this.otp = "";
            } finally {
                this.loading = false;
            }
        },

        async resendOTP() {
            const payload = {
                email: this.form.email,
            };

            if (this.branchManagers)
                payload.branch_manager_id = this.selectedManager;

            const res = await this.RESEND_OTP(payload);

            if (res.result) {
                this.startResendTimer();
                this.$store.dispatch("snackbar/SHOW", res);
            } else {
                this.$store.dispatch("snackbar/SHOW", {
                    ...res,
                    type: "error",
                });
            }
        },

        startResendTimer() {
            this.resendTimer = 60;
            this.timerInterval = setInterval(() => {
                if (this.resendTimer > 0) {
                    this.resendTimer--;
                } else {
                    clearInterval(this.timerInterval);
                }
            }, 1000);
        },

        maskEmail(email) {
            if (!email) return "";
            const [username, domain] = email.split("@");
            const maskedUsername =
                username.charAt(0) +
                "*".repeat(username.length - 2) +
                username.charAt(username.length - 1);
            return `${maskedUsername}@${domain}`;
        },
        async generateOtp() {
            this.loading = true;
            try {
                const res = await this.GENERATE_OTP({
                    user_id: this.userId,
                    branch_manager_id: this.selectedManager,
                });

                if (res.result) {
                    this.$store.dispatch("snackbar/SHOW", res);
                    this.showCheckbox = false;
                } else {
                    this.$store.dispatch("snackbar/SHOW", {
                        ...res,
                        type: "error",
                    });
                }
            } catch (error) {
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        if (this.branchManagers) {
            this.showCheckbox = true;
        }
    },

    beforeDestroy() {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
    },
};
</script>

<style scoped>
.v-otp-input >>> input {
    font-size: 1.5rem !important;
    text-align: center !important;
    border: none !important;
}
</style>
