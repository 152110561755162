<template>
    <div>
        <div class="fill-height slider-cont">
            <div class="modal-inside pb-0 no-tab-right-pane">
                <div class="form-title mb-24">Add Branch</div>
                <v-form ref="form">
                    <div class="tab-content-wrapper">
                        <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                                <label>
                                    <span class="label-txt"> Branch Name </span>
                                </label>
                                <v-text-field
                                    autocomplete="off"
                                    solo
                                    outlined
                                    :rules="rules.branch"
                                    v-model="form.branch"
                                    flat
                                    hide-details="auto"
                                    class="solo-cust"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="e-row">
                            <v-col cols="6" md="6" class="e-col">
                                <label>
                                    <span class="label-txt"> Contact No </span>
                                </label>
                                <v-text-field
                                    autocomplete="off"
                                    solo
                                    outlined
                                    v-model="form.contact_no"
                                    flat
                                    hide-details="auto"
                                    class="solo-cust"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" md="6" class="e-col">
                                <label>
                                    <span class="label-txt"> Email </span>
                                </label>
                                <v-text-field
                                    autocomplete="off"
                                    solo
                                    outlined
                                    v-model="form.email"
                                    flat
                                    hide-details="auto"
                                    class="solo-cust"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                                <label>
                                    <span class="label-txt"> Machines </span>
                                </label>
                                <v-autocomplete
                                    v-model="form.machines"
                                    :items="machines"
                                    item-text="name"
                                    item-value="id"
                                    multiple
                                    chips
                                    autocomplete="off"
                                    solo
                                    outlined
                                    flat
                                    hide-details="auto"
                                    class="este-autocomplete-input"
                                >
                                    <template slot="append">
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                                <label>
                                    <span class="label-txt">
                                        Visibility for Consultants
                                    </span>
                                </label>
                                <v-radio-group
                                    hide-details="auto"
                                    mandatory
                                    v-model="form.visibility_all"
                                    row
                                >
                                    <v-radio
                                        :ripple="false"
                                        off-icon="mdi-checkbox-blank-circle-outline"
                                        on-icon="mdi-check-circle"
                                        hide-details="auto"
                                        label="See all rows"
                                        :value="0"
                                    ></v-radio>
                                    <v-radio
                                        :ripple="false"
                                        off-icon="mdi-checkbox-blank-circle-outline"
                                        on-icon="mdi-check-circle"
                                        hide-details="auto"
                                        label="See rows assigned to them only"
                                        :value="1"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                                <label>
                                    <span class="label-txt"> SMS Credits </span>
                                </label>
                                <v-text-field
                                    :rules="[positiveNumber]"
                                    autocomplete="off"
                                    solo
                                    outlined
                                    v-model="form.sms_credits"
                                    flat
                                    hide-details="auto"
                                    class="solo-cust"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" class="e-col">
                                <label>
                                    <span class="label-txt"> SMS Enabled </span>
                                </label>
                                <v-radio-group
                                    hide-details="auto"
                                    mandatory
                                    v-model="form.send_sms"
                                    row
                                >
                                    <v-radio
                                        :ripple="false"
                                        off-icon="mdi-checkbox-blank-circle-outline"
                                        on-icon="mdi-check-circle"
                                        hide-details="auto"
                                        label="Yes"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        :ripple="false"
                                        off-icon="mdi-checkbox-blank-circle-outline"
                                        on-icon="mdi-check-circle"
                                        hide-details="auto"
                                        label="No"
                                        :value="0"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" md="12" class="e-col">
                                <label>
                                    <span class="label-txt">
                                        Automatic SMS Enabled
                                    </span>
                                </label>
                                <v-radio-group
                                    hide-details="auto"
                                    mandatory
                                    v-model="form.is_auto_send"
                                    row
                                >
                                    <v-radio
                                        :ripple="false"
                                        off-icon="mdi-checkbox-blank-circle-outline"
                                        on-icon="mdi-check-circle"
                                        hide-details="auto"
                                        label="Yes"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        :ripple="false"
                                        off-icon="mdi-checkbox-blank-circle-outline"
                                        on-icon="mdi-check-circle"
                                        hide-details="auto"
                                        label="No"
                                        :value="0"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" md="12" class="e-col">
                                <label>
                                    <span class="label-txt">
                                        Can see points in the App?
                                    </span>
                                </label>
                                <v-radio-group
                                    hide-details="auto"
                                    mandatory
                                    v-model="form.points_visible"
                                    row
                                >
                                    <v-radio
                                        :ripple="false"
                                        off-icon="mdi-checkbox-blank-circle-outline"
                                        on-icon="mdi-check-circle"
                                        hide-details="auto"
                                        label="Yes"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        :ripple="false"
                                        off-icon="mdi-checkbox-blank-circle-outline"
                                        on-icon="mdi-check-circle"
                                        hide-details="auto"
                                        label="No"
                                        :value="0"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <!-- <v-col cols="12" md="12" class="e-col">
                                <label>
                                    <span class="label-txt">
                                        Auto assign opportunities?
                                    </span>
                                </label>
                                <v-radio-group
                                    hide-details="auto"
                                    mandatory
                                    v-model="form.opportunity_auto_assign"
                                    row
                                >
                                    <v-radio
                                        :ripple="false"
                                        off-icon="mdi-checkbox-blank-circle-outline"
                                        on-icon="mdi-check-circle"
                                        hide-details="auto"
                                        label="Yes"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        :ripple="false"
                                        off-icon="mdi-checkbox-blank-circle-outline"
                                        on-icon="mdi-check-circle"
                                        hide-details="auto"
                                        label="No"
                                        :value="0"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" md="12" class="e-col">
                                <label>
                                    <span class="label-txt">Pabau enabled</span>
                                </label>
                                <v-radio-group
                                    hide-details="auto"
                                    mandatory
                                    v-model="form.pabau_enabled"
                                    row
                                >
                                    <v-radio
                                        :ripple="false"
                                        off-icon="mdi-checkbox-blank-circle-outline"
                                        on-icon="mdi-check-circle"
                                        hide-details="auto"
                                        label="Yes"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        :ripple="false"
                                        off-icon="mdi-checkbox-blank-circle-outline"
                                        on-icon="mdi-check-circle"
                                        hide-details="auto"
                                        label="No"
                                        :value="0"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col> -->

                            <v-col cols="12" md="12" class="e-col">
                                <label>
                                    <span class="label-txt">2FA enabled </span>
                                </label>
                                <v-radio-group
                                    hide-details="auto"
                                    mandatory
                                    row
                                    v-model="form.is_2fa_enabled"
                                >
                                    <v-radio
                                        :ripple="false"
                                        off-icon="mdi-checkbox-blank-circle-outline"
                                        on-icon="mdi-check-circle"
                                        hide-details="auto"
                                        label="Yes"
                                        :value="true"
                                    ></v-radio>
                                    <v-radio
                                        :ripple="false"
                                        off-icon="mdi-checkbox-blank-circle-outline"
                                        on-icon="mdi-check-circle"
                                        hide-details="auto"
                                        label="No"
                                        :value="false"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>
            </div>
            <div class="form-btn-wrapper">
                <v-btn
                    class="cancel-btn btn-gap px-15"
                    depressed
                    rounded
                    outlined
                    x-large
                    @click="close"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="secondary"
                    class="b-blue"
                    depressed
                    :disabled="loading"
                    rounded
                    x-large
                    @click="create"
                >
                    Add Branch
                </v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from "../../../../store/action-types.js";
import status from "../../../../mixins/status.js";
import globalFn from "../../../../mixins/globalFn.js";
export default {
    mixins: [status, globalFn],
    data: () => ({
        loading: false,
        user: JSON.parse(localStorage.getItem("user")),
        tab: null,
        form: {
            branch: "",
            machines: [],
            contact_no: "",
            email: "",
            visibility_all: 0,
            is_auto_send: 0,
            send_sms: 0,
            pabau_enabled: 0,
            points_visible: 0,
            opportunity_auto_assign: 0,
            sms_credits: null,
            is_2fa_enabled: false,
        },
        rules: {
            branch: [(v) => !!v || "Please enter branch"],
        },
    }),
    computed: {
        machines() {
            if (this.$store.state.machines.all) {
                return this.$store.state.machines.all;
            }
        },
    },
    created() {
        this.$store.dispatch("machines/GET_MACHINE_LIST", "");
    },
    methods: {
        close() {
            this.$store.commit("rightSlider/RESET_SLIDER");
        },
        loadData() {
            this.$root.$emit("branchTableUpdate", "");
            this.$store.commit("rightSlider/RESET_SLIDER");
        },
        async create() {
            let formData = {};
            formData.branch = this.form.branch;
            formData.contact_no = this.form.contact_no;
            formData.email = this.form.email;
            formData.visibility_all = this.form.visibility_all;
            formData.send_sms = this.form.send_sms;
            formData.is_auto_send = this.form.is_auto_send;
            formData.sms_credits = this.form.sms_credits;
            formData.points_visible = this.form.points_visible;
            // formData.opportunity_auto_assign =
            //     this.form.opportunity_auto_assign;
            // formData.pabau_enabled = this.form.pabau_enabled;
            formData.is_2fa_enabled = this.form.is_2fa_enabled;

            formData.machines = [];
            this.form.machines.forEach((value) => {
                if (value.id) {
                    formData.machines.push({ id: value.id });
                } else {
                    formData.machines.push(value);
                }
            });
            if (this.$refs.form.validate()) {
                this.loading = true;
                await this.$store
                    .dispatch("branches/CREATE_BRANCH", formData)
                    .then((response) => {
                        if (response.result === "success") {
                            this.loading = false;
                            this.loadData();
                        } else if (response.result === "fail") {
                            response.type = "error";
                            this.loading = false;
                            this.$store.dispatch("snackbar/SHOW", response);
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                        err.type = "error";
                        this.$store.dispatch("snackbar/SHOW", err);
                    });
            }
        },
    },
};
</script>
