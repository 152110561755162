import http from "../http/common";

export default {
    login: (payload) => http.post("/auth/login", payload),

    signout: (payload) => http.post("/auth/logout", payload),

    getUser: (payload) => http.get("/auth/get-details", payload),

    passwordResetLink: (payload) => http.post(`auth/password/create`, payload),

    passwordReset: (payload) => http.post(`auth/password/reset`, payload),

    verifyPasswordResetLink: (token) => http.get(`auth/password/find/${token}`),

    verfiyOtp: (payload) => http.post(`auth/verify_2fa_otp`, payload),

    resendOtp: (payload) => http.post(`auth/resend_2fa_otp`, payload),

    generateOtp: (payload) => http.post(`auth/generate_otp`, payload),
};
